import { PropertyUse } from "gql/graphql";

export const validations = {
  startedAt: {
    min: "1980-01",
    max: "2050-12",
  },
  completedAt: {
    min: "1980-01",
    max: "2050-12",
  },
};

export const customMessages = {
  startedAt: {
    beforeCompletedAt: "Start date must be before completion date",
    min: `Must be ${validations.startedAt.min.slice(0, 4)} or later`,
    max: `Must be ${validations.startedAt.max.slice(0, 4)} or earlier`,
  },
  completedAt: {
    min: `Must be ${validations.completedAt.min.slice(0, 4)} or later`,
    max: `Must be ${validations.completedAt.max.slice(0, 4)} or earlier`,
  },
  address: {
    postalCode: {
      pattern:
        "Please enter a valid postal code for the country selected. For the USA, please only use 5 digits",
      invalid: "Invalid postal code.",
    },
    latitude: {
      required: "Please make sure address or valid postal code is present",
    },
  },
  aboveGroundFloors: {
    count: {
      min: "Must be greater than 1",
      max: "We don't support more than 110 floors",
    },
    areaPerFloor: {
      area: {
        min: "Must be greater than 100 sqft.",
        max: "Floor area too large.",
      },
    },
  },
  belowGroundFloors: {
    count: {
      min: "If set, must be at least 1",
      max: "We don't support more than 15 below ground floors",
    },
    areaPerFloor: {
      area: {
        min: "Must be greater than 100 sqft.",
        max: "Floor area too large.",
      },
    },
  },
  parkingFloors: {
    count: {
      min: "Must be equal to 1",
      max: "Must be equal to 1",
    },
    areaPerFloor: {
      area: {
        min: "Must be greater than 100 sqft.",
        max: "Floor area too large.",
      },
    },
  },
};

const GuidedProjectOnboardingConstants = {
  validations,
  customMessages,
};

export default GuidedProjectOnboardingConstants;

export const PROPERTY_USE_ICONS = {
  [PropertyUse.DataCenter]: "storage",
  [PropertyUse.Education]: "school",
  [PropertyUse.Healthcare]: "home_health",
  [PropertyUse.Hospitality]: "hotel",
  [PropertyUse.Industrial]: "corporate_fare",
  [PropertyUse.Office]: "work",
  [PropertyUse.Retail]: "shopping_bag",
  [PropertyUse.Multifamily]: "apartment",
};
