import { useContext, useMemo, useRef } from "react";
import { renderToString } from "react-dom/server";
import _, { map } from "lodash";
import numeral from "numeral";
import clsx from "clsx";
import { axisLeft } from "d3-axis";
import { scaleBand, scaleLinear } from "d3-scale";
import { select } from "d3-selection";
import "d3-transition";

import WithLoadingState from "components/Reporting/WithLoadingState";
import { DataLoadingContext } from "context/DataLoadingContext";
import { ReportCategoryEdge } from "gql/graphql";
import { ContainerDimensions } from "types";
import { useContainerDimensions } from "utils/CustomHooks";
import { KGCO2E } from "utils/formatting";
import { setupTooltip } from "utils/visualizations";

import "./CarbonImpactByCategory.scss";
import VisualizationTooltip from "components/Reporting/VisualizationTooltip";

interface CarbonImpactDatum {
  absoluteCarbon: number | null;
  carbonProportion: number | null;
  category: string;
  defaultBuildingElement?: "structure" | "enclosure" | "interior" | "services";
}

const VISUALIZATION_ID = "carbon-impact-by-category";

interface Props {
  data: ReportCategoryEdge[];
}

const CarbonImpactByProductCategory = ({ data }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { dataLoading } = useContext(DataLoadingContext);

  const formattedData = useMemo(
    () =>
      _.orderBy(
        _.map(data, (edge) => ({
          category: edge?.node?.name,
          carbonProportion: edge?.proportion,
          absoluteCarbon: edge?.total?.kgCo2e,
          defaultBuildingElement: edge?.node?.defaultBuildingElement,
        })),
        "carbonProportion",
        "desc"
      ),
    [data]
  );

  useContainerDimensions({
    buildVisualization,
    buildVisualizationArgs: [formattedData],
    containerRef,
    updateOnResize: true,
  });

  return (
    <div id={VISUALIZATION_ID}>
      <div className="title">
        <p className="subtitle-1">Product Category</p>
      </div>
      <VisualizationTooltip />
      <div
        role="figure"
        ref={containerRef}
        style={{ flex: 1, minHeight: 0, width: "100%" }}
      >
        <WithLoadingState isLoading={dataLoading} height={200}>
          <svg>
            <g
              className="bars"
              transform={`translate(${MARGINS.left},${MARGINS.top})`}
            />
            <g className="y-axis" />
            <g
              className="full-width-hover-bars"
              transform={`translate(${MARGINS.left},${MARGINS.top})`}
            />
          </svg>
        </WithLoadingState>
      </div>
    </div>
  );
};

export default CarbonImpactByProductCategory;

const MARGINS = { top: 0, bottom: 0, left: 0, right: 5 };
const LABEL_BUFFER = 160;
const PERCENTAGE_LABEL_WIDTH = 50;
const BAR_HEIGHT = 32;
const BAR_SPACING = 10;

const MAX_BAR_COUNT = 10;

// given d in data, returns the (ordinal) y-value
const y = (d: CarbonImpactDatum) => d.category;

const buildVisualization = (
  {
    width: containerWidth,
    height: containerHeight,
    transition: shouldTransition = true,
  }: ContainerDimensions,
  data: CarbonImpactDatum[]
) => {
  const vizWidth = containerWidth;

  const dataOfSignificance = _.reject(
    data,
    (d) => (d.carbonProportion || 0) < 0.01
  );
  let limitedData = _.take(dataOfSignificance, MAX_BAR_COUNT);

  if (
    _.size(dataOfSignificance) > MAX_BAR_COUNT ||
    _.size(dataOfSignificance) !== _.size(data)
  ) {
    limitedData = [
      ...limitedData,
      {
        category: "Other",
        carbonProportion:
          1 - _.sumBy(dataOfSignificance, (d) => d.carbonProportion || 0),
      } as CarbonImpactDatum,
    ];
  }

  // Construct scales and axes
  const yRange = [
    MARGINS.top,
    _.size(limitedData) * (BAR_HEIGHT + BAR_SPACING) + MARGINS.top,
  ];
  const mapped = map(limitedData, y);
  const yScale = scaleBand().domain(mapped).range(yRange);

  const xScale = scaleLinear([0, vizWidth - MARGINS.right])
    .domain([0, 1])
    .clamp(true);

  const yAxis = axisLeft(yScale).tickSizeOuter(0);

  const container = select(`#${VISUALIZATION_ID}`);

  const svg = container
    .select("svg")
    .attr("width", "100%")
    .attr(
      "height",
      _.size(limitedData) * (BAR_HEIGHT + BAR_SPACING) +
        MARGINS.top +
        MARGINS.bottom
    );

  const bars = svg
    .select("g.bars")
    .selectAll("rect")
    .data(limitedData)
    .join("rect")
    .attr("x", (d) => xScale(0))
    .attr("y", (d: any) => yScale(d.category) as number)
    .attr("class", (d) => (d.defaultBuildingElement || "").toLowerCase())
    .attr("height", BAR_HEIGHT)
    .attr("data-testid", "bar");

  const hoverBars = svg
    .select("g.full-width-hover-bars")
    .selectAll("rect")
    .data(_.reject(limitedData, ({ category }) => category === "Other"))
    .join("rect")
    .attr("x", (d) => xScale(0))
    .attr("y", (d: any) => yScale(d.category) as number)
    .attr("height", BAR_HEIGHT)
    .attr("width", containerWidth - MARGINS.right)
    .attr("fill", "transparent");

  bars
    .transition()
    .duration(shouldTransition ? 500 : 0)
    .attr("width", (d) => xScale(d.carbonProportion || 0));

  svg
    .select("g.y-axis")
    .attr(
      "transform",
      `translate(${vizWidth - LABEL_BUFFER - PERCENTAGE_LABEL_WIDTH}, ${
        MARGINS.top
      })`
    )
    .call(yAxis as any)
    .call((g) => g.select(".domain").remove())
    .call((g) => g.selectAll(".tick line").remove())
    .call((g) => {
      // prevent appending texts and rects every time component re-renders
      g.selectAll(".tick text").remove();
      g.selectAll(".tick rect").remove();

      g.selectAll(".tick").each(function (d: any) {
        // white background for text to prevent bar running over text
        const backgroundRectOne = select(this)
          .append("rect")
          .attr("width", "unset")
          .attr("height", BAR_HEIGHT / 2)
          .attr("y", -12.5)
          .attr("x", -MARGINS.right);

        // category label
        const categoryTextLabel = select(this)
          .append("text")
          .attr("class", clsx("body-1", "category"))
          .text(() => _.truncate(d as string, { length: 24 }));

        backgroundRectOne.attr(
          "width",
          // we only know how wide the background rect should once the text has been appended
          // but the background rect needs to be appended before the label due to z-positioning
          (categoryTextLabel.node()?.getBoundingClientRect().width || 0) + 12.5
        );

        const percentageLabelTextPosition =
          LABEL_BUFFER + PERCENTAGE_LABEL_WIDTH - MARGINS.right;

        const backgroundRectTwo = select(this)
          .append("rect")
          .attr("width", "unset")
          .attr("height", BAR_HEIGHT / 2)
          .attr("x", percentageLabelTextPosition)
          .attr("y", -12.5);

        // percentage label
        const percentageTextLabel = select(this)
          .append("text")
          .attr("class", clsx("body-1", "percentage"))
          .attr("x", percentageLabelTextPosition)
          .attr("dx", -2)
          .text((d) => {
            const datum = _.find(limitedData, {
              category: d,
            }) as CarbonImpactDatum;

            if (!datum.carbonProportion) {
              return "--%";
            }
            return _.inRange(datum.carbonProportion, -0.01, 0.01)
              ? "< 1% "
              : numeral(datum.carbonProportion).format("0%");
          });

        const percentageTextLabelWidth =
          percentageTextLabel.node()?.getBoundingClientRect().width || 0;

        backgroundRectTwo
          .attr("width", percentageTextLabelWidth + 8)
          // we only know how wide the background rect should once the text has been appended
          // but the background rect needs to be appended before the label due to z-positioning
          .attr(
            "x",
            percentageLabelTextPosition -
              percentageTextLabelWidth -
              MARGINS.right
          );
      });
    });

  const tooltip = container.select(".tooltip");
  setupTooltip([hoverBars], tooltip, getTooltipContent, {
    width: containerWidth,
    height: containerHeight,
  });

  return svg.node();
};

const getTooltipContent = (d: CarbonImpactDatum) => `
  <span><strong>${_.capitalize(d.category)}</strong></span>
  <span>Total carbon <strong>${renderToString(
    <KGCO2E kgCo2e={d.absoluteCarbon} bold />
  )}</strong></span>
`;
