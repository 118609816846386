import _, { min } from "lodash";

import {
  AreaUnit,
  BuildingElementsEnum,
  PortfolioProjectConnection,
  Project,
  ProjectDataFidelityEnum,
  ReportTotalImpactByBuildingElement,
  ReportTotalImpactByBuildingElementConnection,
} from "gql/graphql";
import { ContainerDimensions } from "types";
import { convertIntensity } from "utils/transforms";
import {
  StackingDataFields,
  setupTooltip,
  transformDataForStacking,
} from "utils/visualizations";
import { scaleBand, scaleLinear } from "d3-scale";
import { select, Selection } from "d3-selection";
import { max } from "d3-array";
import { axisBottom, axisLeft } from "d3-axis";
import { formatYAxisTicks } from "../utils";
import clsx from "clsx";
import { renderToString } from "react-dom/server";
import { KGCO2E } from "utils/formatting";

export type EmissionsTargetConfig = {
  description: string;
  intensity: number;
  label: string;
};

export type BuildViz = (
  dims: ContainerDimensions,
  data: PortfolioProjectConnection["nodes"],
  areaUnit: AreaUnit,
  emissionsTargetConfig: EmissionsTargetConfig,
  navigate: (path: string) => void
) => void;

interface Datum {
  byBuildingElements: (Partial<ReportTotalImpactByBuildingElement> &
    StackingDataFields)[];
  carbonIntensity: number;
  name: string;
  slug: string;
  startedAt: string;
}

export const VIZ_ID = "building-leaderboard-stacked-bar-chart";

const CONTAINER_HEIGHT = 240;
const PADDING_Y_SCALE = 40;
const MAX_BAR_WIDTH = 32;
const GAP_BETWEEN_BARS = 2;
const INTENSITY_TARGET_SLUG = "intensity-target";

export const MARGINS = {
  top: 0,
  left: 44,
  bottom: 50,
  right: 10,
};

const Y_ACCESSOR_FIELD = "carbonIntensity";

const transformData = (
  data: ReportTotalImpactByBuildingElementConnection["edges"]
) => {
  const order = [
    BuildingElementsEnum.Structure,
    BuildingElementsEnum.Enclosure,
    BuildingElementsEnum.Interior,
    BuildingElementsEnum.Services,
  ];

  const formattedData = _.chain(data)
    .map("node")
    .orderBy((d) => _.indexOf(order, _.toString(d.buildingElements)))
    .value();
  return transformDataForStacking(formattedData, "perArea.kgCo2e");
};

export const buildVisualization: BuildViz = (
  { width: containerWidth },
  data = [],
  areaUnit: AreaUnit,
  emissionsTargetConfig: EmissionsTargetConfig,
  navigate: (path: string) => void
) => {
  let transformedData = _.chain(data as Project[])
    .map((node: Project) => ({
      name: node.name,
      slug: node.slug,
      startedAt: node.startedAt,
      [Y_ACCESSOR_FIELD]: node?.report?.carbon?.total?.perArea?.kgCo2e,
      byBuildingElements: transformData(
        node?.report?.carbon?.buildingElements?.edges
      ),
      dataFidelity: node?.dataFidelity,
    }))
    .filter((d) => d[Y_ACCESSOR_FIELD] > 0)
    .value();

  const emissionsTargetFt2 = convertIntensity(
    emissionsTargetConfig.intensity,
    AreaUnit.M2,
    AreaUnit.Ft2
  );

  transformedData = _.concat(
    {
      name: emissionsTargetConfig.label,
      slug: INTENSITY_TARGET_SLUG,
      [Y_ACCESSOR_FIELD]:
        areaUnit === AreaUnit.M2
          ? emissionsTargetConfig.intensity
          : emissionsTargetFt2,
      byBuildingElements: [],
      dataFidelity: null,
      startedAt: null,
    },
    transformedData
  );

  const vizWidth = containerWidth - MARGINS.left - MARGINS.right;

  const vizHeight = CONTAINER_HEIGHT - MARGINS.top - MARGINS.bottom;

  const xDomain = _.map(transformedData, (d) => d.slug);
  const xScale = scaleBand()
    .domain(xDomain)
    .range([0, vizWidth])
    .paddingInner(0.25);

  const maxCarbon = max(transformedData, (d) => d?.[Y_ACCESSOR_FIELD]);

  const yDomain = [0, maxCarbon || 0];
  const yScale = scaleLinear()
    .domain(yDomain)
    .range([vizHeight, PADDING_Y_SCALE])
    .unknown(vizHeight);

  const container = select(`#${VIZ_ID}`);

  const svg = container
    .select("svg")
    .attr("width", "100%")
    .attr("height", CONTAINER_HEIGHT);

  svg
    .select(".y-axis")
    .call(axisLeft(yScale).ticks(3).tickFormat(formatYAxisTicks) as any)
    .call((selection) => {
      // customizing the y axis
      selection.selectAll(".domain").remove();
      selection.selectAll(".tick line").attr("x2", vizWidth); // making full grid lines
    });

  const stackedBarGroups = svg
    .select("g.bars")
    .selectAll("g.stacked-bar")
    .data<Datum>(transformedData, (d) => (d as Datum)?.slug)
    .join("g")
    .attr("class", (d) =>
      clsx(["stacked-bar", { target: d.slug === INTENSITY_TARGET_SLUG }])
    )
    .attr("transform", (d) => {
      const xPos =
        (xScale(d.slug) || 0) + xScale.bandwidth() / 2 - MAX_BAR_WIDTH / 2;
      return `translate(${xPos},0)`;
    });

  // draw the stacked rectangles
  stackedBarGroups.each(function (groupData) {
    const barGroup = select(this);
    const stackedData = groupData.byBuildingElements;

    if (groupData.slug === INTENSITY_TARGET_SLUG) {
      barGroup
        .selectAll("rect")
        .data([groupData])
        .join("rect")
        .attr("y", (d) => {
          const height = vizHeight - yScale(d[Y_ACCESSOR_FIELD]) || 0;
          return vizHeight - height;
        })
        .attr("height", (d) => vizHeight - yScale(d[Y_ACCESSOR_FIELD]) || 0)
        .attr("width", MAX_BAR_WIDTH);
    } else {
      barGroup
        .selectAll("rect")
        .data(stackedData)
        .join("rect")
        .attr("class", (d) =>
          clsx([
            _.toLower(_.join(d.buildingElements, "_")),
            { hatched: d.dataFidelity === ProjectDataFidelityEnum.Estimated },
          ])
        )
        .attr("y", (d, i) => {
          // Rfactor this code
          const height = vizHeight - yScale(d.perArea?.kgCo2e as number) || 0;

          const previousHeight = vizHeight - yScale(d.trailingCumulative) || 0;

          return vizHeight - height - previousHeight - GAP_BETWEEN_BARS * i;
        })
        .attr(
          "height",
          (d) => vizHeight - yScale(d.perArea?.kgCo2e as number) || 0
        )
        .attr(
          "width",
          _.toString(min([xScale.bandwidth() as number, MAX_BAR_WIDTH]))
        )
        .attr(
          "fill",
          (d) =>
            d.dataFidelity === ProjectDataFidelityEnum.Estimated
              ? `url(#${_.toLower(_.join(d.buildingElements, "+"))})`
              : "unset" // set in CSS
        )
        .on("click", () => {
          navigate(`/my-projects/${groupData.slug}`);
        });
    }

    barGroup
      .selectAll(".label")
      .data([groupData.carbonIntensity])
      .join("text")
      .attr("class", "label")
      .text((d) => Math.round(d))
      .attr("y", (d) => {
        // DRY this up

        if (groupData.name === emissionsTargetConfig.label) {
          return yScale(d);
        }
        const lastDatum = _.last(stackedData);
        if (lastDatum) {
          const height =
            vizHeight - yScale(lastDatum.perArea?.kgCo2e as number) || 0;
          const previousHeight =
            vizHeight - yScale(lastDatum.trailingCumulative) || 0;

          return (
            vizHeight -
            height -
            previousHeight -
            GAP_BETWEEN_BARS * _.size(groupData.byBuildingElements)
          );
        } else {
          return yScale(0);
        }
      })
      .attr("dy", -4)
      .attr("dx", MAX_BAR_WIDTH / 2);
  });

  const tooltip = container.select(".tooltip");

  setupTooltip(
    [stackedBarGroups],
    tooltip,
    (d) => getTooltipContent(d, areaUnit),
    {
      width: containerWidth,
      height: CONTAINER_HEIGHT,
    }
  );

  const xAxis = svg
    .select(".x-axis")
    .attr("transform", `translate(${MARGINS.left}, ${vizHeight})`)
    .call(
      axisBottom(xScale).tickFormat((d) => {
        const slug = d;
        if (slug === INTENSITY_TARGET_SLUG) return emissionsTargetConfig.label;
        const datum = _.find(transformedData, { slug });

        return datum?.name || "";
      }) as any
    )
    .call((selection) => {
      // customize the x-axis
      selection.selectAll(".domain").remove();
      selection.selectAll(".tick line").remove();
    });

  xAxis.selectAll(".tick text").call(wrapText, 12 * 6); // Approximate width for 12 characters
};

interface TooltipProps {
  areaUnit: AreaUnit;
  d: Datum;
}

const TooltipContent = ({ d, areaUnit }: TooltipProps) => (
  <>
    <span key="name" className="bold">
      {d.name}
    </span>
    <span key="carbon-intensity">
      <span className="bold">{Math.round(d.carbonIntensity)} </span>
      {<KGCO2E unit={areaUnit} />}
    </span>
    {_.map(d.byBuildingElements, ({ buildingElements, percent }) => {
      return (
        <span key={_.join(buildingElements, "-")}>
          {_.capitalize(_.join(buildingElements, " + "))}
          {` `}
          <span className="bold">{Math.round(percent)}%</span>
        </span>
      );
    })}
  </>
);
const getTooltipContent = (d: Datum, areaUnit: AreaUnit) =>
  renderToString(<TooltipContent d={d} areaUnit={areaUnit} />);

function wrapText(text: Selection<any, any, any, any>, width: number) {
  text.each(function () {
    const self = select(this);
    const words = self.text().split(/\s+/).reverse();
    const y = self.attr("y");
    const dy = parseFloat(self.attr("dy")) || 0;
    const lineHeight = 1.1; // ems

    let word,
      line: string[] = [],
      lineNumber = 0,
      tspan = self
        .text(null)
        .append("tspan")
        .attr("x", 0)
        .attr("y", y)
        .attr("dy", dy + "em");

    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(" "));

      // @ts-ignore
      if (tspan.node().getComputedTextLength() > width) {
        // @ts-ignore
        line.pop();

        if (lineNumber === 1) {
          tspan.text(line.join(" ") + "..."); // Add ellipsis and end  (i.e. limit to 2 lines)
          break;
        }

        tspan.text(line.join(" "));
        line = [word];
        tspan = self
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + dy + "em")
          .text(word);
      }
    }
  });
}
